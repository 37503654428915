<template>
  <div id="bookings-list">
    <!-- DESKTOP VERSION -->
    <b-row class="d-none d-md-block">
      <b-col>
        <b-card>
          <bookings-table
            :loading="loading"
            :bookings="bookings"
            :filters="filterOptions"
            :hide-accommodation-name="hideAccommodationName"
            @booking-selected="onBookingSelected"
          />
        </b-card>
      </b-col>
    </b-row>

    <!-- MOBILE VERSION -->
    <b-row class="d-md-none">
      <b-col>
        <mobile-bookings-list-card
          :loading="loading"
          :bookings="bookings"
          :filters="filterOptions"
          :hide-accommodation-name="hideAccommodationName"
          @booking-selected="onBookingSelected"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BookingsTable from "@/views/bookings/list/components/BookingsTable.vue";
import MobileBookingsListCard from "@/views/bookings/list/components/MobileBookingsListCard.vue";
import { BRow, BCol, BCard } from "bootstrap-vue";
import { notifyError } from "@methods";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BookingsTable,
    MobileBookingsListCard,
  },
  computed: {
    loading() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    bookings() {
      return this.$store.getters["owner/bookings"];
    },
    hideAccommodationName() {
      return !this.$store.getters["ui/showAccommodationName"];
    },
    filterOptions() {
      return [
        { value: "CONFIRMED", label: this.$t("Upcoming-f") },
        { value: "COMPLETED", label: this.$t("Completed-f") },
        { value: "CANCELLED", label: this.$t("Cancelled-f") },
        { value: "ALL", label: this.$t("All-f") },
      ];
    },
  },
  created() {
    if (!this.owner) this.fetchOwner();
  },
  methods: {
    fetchOwner() {
      const userData = JSON.parse(
        localStorage.getItem("foravilaOwnersUserData")
      );
      const ownerUuid = userData?.owner?.uuid || null;
      this.$store.dispatch("owner/fetchOwner", ownerUuid).catch(() => {
        notifyError(
          this.$t("errors.fetchBookings.title"),
          this.$t("errors.fetchBookings.text")
        );
      });
    },
    onBookingSelected(localizator) {
      this.$router.push({
        name: "foravila-booking-view",
        params: { localizator },
      });
    },
  },
};
</script>
